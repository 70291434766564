
















import Keyboard, { Key } from "@/keyboard";
import { defineComponent } from "@vue/composition-api";

interface Props {
  keyboard: Keyboard;
  keysToType: Record<string, boolean>;
}

export default defineComponent({
  props: {
    keyboard: Keyboard,
    keysToType: Object,
  },

  setup(props: Props) {
    const isHighlighted = (k: Key) => {
      return props.keysToType[k.ch] || props.keysToType[k.id];
    };

    const classFor = (k: Key) => {
      return {
        key: true,
        highlight: isHighlighted(k),
        ["finger-" + k.finger]: true,
      };
    };

    return {
      isHighlighted,
      classFor,
    };
  },
});
