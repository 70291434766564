























import { defineComponent, getCurrentInstance } from "@vue/composition-api";

import groupBy from "lodash.groupby";
import { getExercises } from "@/store/exercise";

export default defineComponent({
  setup() {
    const router = (getCurrentInstance()!.proxy as any).$router;
    const chapters = groupBy(getExercises(), "chapter_number");

    const go = (exid: string) => {
      router.push("/exercises/" + exid);
    };

    return {
      chapters,
      go,
    };
  },
});
