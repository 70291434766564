import dayjs from "dayjs";
import { Score, User } from "./types";

export function formatTime(t: firebase.default.firestore.Timestamp): string {
  return dayjs.unix(t.seconds).format("YYYY-MM-DD HH:mm:ss");
}

export function calcScore(score: Score): number {
  return calcScoreFromWpm(score.wpm, score.accuracy);
}

export function calcScoreFromWpm(wpm: number, accuracy: number): number {
  return Math.floor(wpm * 5 * Math.pow(accuracy, 3));
}

export function calcWpm(elapsed: number, length: number): number {
  return length / 5 / (elapsed / 1000 / 60.0);
}

export function userName(user: User): string {
  return user?.name || user?.fullname;
}
