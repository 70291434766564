










import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    hand: String,
    fingers: Array,
  },
});
