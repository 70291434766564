






import firebase from "@/firebase";
import { defineComponent, onMounted, reactive } from "@vue/composition-api";

interface State {
  error: firebase.auth.Error | null;
}

export default defineComponent({
  setup(props, { root }) {
    const state: State = reactive({
      error: null,
    });

    onMounted(() => {
      const token = root.$route.query.token as string;
      if (!token) {
        return;
      }
      firebase
        .auth()
        .signInWithCustomToken(token)
        .catch((error) => {
          state.error = error;
        })
        .then(() => {
          root.$router.replace({ path: "/" });
        });
    });

    return { state };
  },
});
