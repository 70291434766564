
























































































import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from "@vue/composition-api";

import firebase from "@/firebase";
import groupBy from "lodash.groupby";
import maxBy from "lodash.maxby";

import { exerciseExists, exerciseTitle, getExercises } from "@/store/exercise";
import { formatTime, calcScore } from "@/util";

import { Score } from "@/types";

interface Props {
  uid: string;
}

export default defineComponent({
  props: ["uid"],

  setup(props: Props) {
    const numRecentScores = 5;

    const scores = ref<Score[]>([]);
    let unbindScores: () => void;

    onMounted(() => {
      unbindScores = firebase
        .firestore()
        .collection("violet")
        .doc(props.uid)
        .collection("scores")
        .orderBy("time", "desc")
        .onSnapshot((querySnapshot) => {
          scores.value = querySnapshot.docs.map((doc) => doc.data() as Score);
        });
    });

    onUnmounted(() => {
      unbindScores && unbindScores();
    });

    const exercises = getExercises();
    const scoresByExercises = computed(() => {
      return Object.fromEntries(
        Object.entries(groupBy(scores.value, "exercise")).map(
          ([exid, scores]) => {
            return [
              exid,
              scores.sort((a, b) => b.time.seconds - a.time.seconds),
            ];
          }
        )
      );
    });

    const bestScoreInExercise = computed(() => {
      return Object.fromEntries(
        Object.entries(scoresByExercises.value).map(([exid, scores]) => {
          return [exid, maxBy(scores, (s: Score) => calcScore(s))];
        })
      );
    });

    const averageScores = computed(() => {
      return Object.fromEntries(
        Object.entries(scoresByExercises.value).map(([exid, scores]) => {
          const recentScores = scores.slice(0, numRecentScores);

          return [
            exid,
            recentScores
              .map((s) => calcScore(s))
              .reduce((acc, current) => acc + current) / recentScores.length,
          ];
        })
      );
    });

    return {
      exercises,
      scoresByExercises,
      bestScoreInExercise,
      averageScores,

      formatTime,
      exerciseExists,
      exerciseTitle,
      calcScore,
    };
  },
});
