























































































import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";

import { getModule } from "vuex-module-decorators";

import TargetText from "@/components/TargetText.vue";
import Guide from "@/components/Guide.vue";
import {
  findExerciseById,
  findNextExerciseById,
  Exercise,
} from "@/store/exercise";
import { calcScoreFromWpm, calcWpm } from "@/util";

import KeyboardStore from "@/store/keyboard";
import SessionStore from "@/store/session";
import firebase from "@/firebase";

function useTimer() {
  let startedAt = ref<Date | null>(null);
  let stoppedAt = ref<Date | null>(null);
  let currentTime = ref(new Date());
  let timer: number | null = null;

  function tick(): void {
    currentTime.value = new Date();
  }

  onMounted(() => {
    timer = setInterval(tick, 100);
  });

  onUnmounted(() => {
    if (timer) {
      clearInterval(timer);
    }
  });

  function reset() {
    startedAt.value = null;
    stoppedAt.value = null;
  }

  function start() {
    startedAt.value = new Date();
  }

  function stop() {
    stoppedAt.value = new Date();
  }

  const elapsed = computed(() => {
    const start = startedAt.value;
    if (!start) {
      return null;
    }

    const end = stoppedAt.value ? stoppedAt.value : currentTime.value;
    return end.valueOf() - start.valueOf();
  });

  reset();

  return {
    reset,
    start,
    stop,
    elapsed,
  };
}

function useErrorTracker() {
  let errors = new Map<number, Map<number, number>>();
  const numErrorsCached = ref(0);

  function reset() {
    errors = new Map<number, Map<number, number>>();
    numErrorsCached.value = 0;
  }

  function incrementError(part: number, pos: number): void {
    let errorsInPart = errors.get(part);
    if (!errorsInPart) {
      errorsInPart = new Map<number, number>();
    }

    const numErrorsAtPos = errorsInPart.get(pos) || 0;
    errorsInPart.set(pos, numErrorsAtPos + 1);
    errors.set(part, errorsInPart);

    numErrorsCached.value = numErrors();
  }

  function numErrors(): number {
    // can't be a computed property because of nested map
    let total = 0;
    errors.forEach((partErrors) => {
      total += partErrors.size;
    });
    return total;
  }

  function errorsInPart(part: number) {
    return errors.get(part);
  }

  return {
    reset,
    incrementError,

    errorsInPart,
    numErrorsCached,
  };
}

interface State {
  state: string;
  part: number;
  pos: number;
}

export default defineComponent({
  components: {
    TargetText,
    Guide,
  },

  setup(props, { root }) {
    const keyboardModule = getModule(KeyboardStore, root.$store);
    const sessionModule = getModule(SessionStore, root.$store);
    const db = firebase.firestore();

    const timer = useTimer();

    function inSeconds(t: number | null) {
      return t ? t / 1000 : "";
    }

    const keyboardType = computed(() => keyboardModule.keyboardType);

    const errorTracker = useErrorTracker();

    const state: State = reactive({
      state: "before-start",
      part: 0,
      pos: 0,
    });

    function retry() {
      timer.reset();
      errorTracker.reset();
      state.part = 0;
      state.pos = 0;
      state.state = "before-start";
    }

    watch(
      () => root.$route,
      () => {
        retry(); // without this, state doesn't reset on execise transition
      }
    );

    const exercise = computed(
      () => findExerciseById(root.$route.params.id) as Exercise
    );
    const nextExercise = computed(() =>
      findNextExerciseById(root.$route.params.id)
    );

    const texts = computed(() => exercise.value.texts);

    const text = computed(() => texts.value[state.part]);

    const nextChar = computed(() => {
      if (!text.value) {
        return undefined;
      }
      return text.value[state.pos];
    });

    const totalChars = computed(() => {
      let n = 0;
      texts.value.forEach((text) => {
        n += text.length;
      });
      return n;
    });

    const wpm = computed(
      () =>
        timer.elapsed.value && calcWpm(timer.elapsed.value, totalChars.value)
    );

    const accuracy = computed(() => {
      return 1.0 - errorTracker.numErrorsCached.value / totalChars.value;
    });

    const score = computed(
      () => wpm.value && calcScoreFromWpm(wpm.value, accuracy.value)
    );

    function recordScore(): void {
      const elapsed = timer.elapsed.value as number;
      const uid = sessionModule.currentUid;

      db.collection("violet").doc(uid).collection("scores").doc().set({
        uid,
        exercise: exercise.value?.id,
        totalChars: totalChars.value,
        numErrors: errorTracker.numErrorsCached.value,
        elapsed: elapsed,
        wpm: wpm.value,
        accuracy: accuracy.value,
        time: firebase.firestore.FieldValue.serverTimestamp(),
      });
    }

    function onKey(e: KeyboardEvent): void {
      switch (state.state) {
        case "before-start":
          if (e.key === "Enter") {
            state.state = "typing";
            timer.start();
            state.part = 0;
            state.pos = 0;
          }
          break;
        case "typing":
          if (e.key.length === 1) {
            if (e.key === text.value[state.pos]) {
              // correct
              state.pos++;
            } else {
              // wrong
              errorTracker.incrementError(state.part, state.pos);
            }
            if (text.value.length === state.pos) {
              // this was the last letter
              state.part++;
              state.pos = 0;
              if (state.part >= texts.value.length) {
                timer.stop();
                state.state = "done";
                recordScore();
              }
            }
          }
          break;
        case "done":
          if (e.key === "Enter") {
            const nex = nextExercise.value;
            if (nex) {
              root.$router.push("/exercises/" + nex.id);
            }
          } else if (e.key === " ") {
            retry();
          }
          break;
      }
    }

    onMounted(() => {
      window.addEventListener("keydown", onKey);
    });

    onUnmounted(() => {
      window.removeEventListener("keydown", onKey);
    });

    return {
      inSeconds,

      state,

      keyboardType,
      exercise,
      nextExercise,

      texts,
      text,
      nextChar,

      timer,
      errorTracker,

      totalChars,
      accuracy,
      wpm,
      score,

      retry,
    };
  },
});
