



































import { defineComponent, reactive } from "@vue/composition-api";
import firebase from "@/firebase";

export default defineComponent({
  setup(props, { root }) {
    const state = reactive({
      email: "",
      password: "",
      inProgress: false,
    });

    const handleSubmit = async () => {
      state.inProgress = true;
      try {
        await firebase
          .auth()
          .signInWithEmailAndPassword(state.email, state.password);

        root.$router.push("/");
      } catch (err) {
        alert(err.message);
      }
      state.inProgress = false;
    };

    return {
      state,
      handleSubmit,
    };
  },
});
