class AlphabetKey {
  public id = "";
  public finger: number;
  public ch: string;
  public sch: string;
  public label: string;
  public width = "";
  public slabel = "";
  constructor(finger: number, ch: string) {
    this.finger = finger;
    this.ch = ch.toLocaleLowerCase();
    this.sch = ch.toUpperCase();
    this.label = ch.toUpperCase();
  }
}

class SymbolKey {
  public id = "";
  public finger: number;
  public ch: string;
  public sch: string;
  public label: string;
  public slabel: string;
  public width = "";
  constructor(finger: number, ch: string, sch: string) {
    this.finger = finger;
    this.ch = ch;
    this.sch = sch;
    this.label = ch;
    this.slabel = sch;
  }
}

class SpecialKey {
  public id: string;
  public finger: number;
  public label: string;
  public width: string;
  public ch = "";
  public sch = "";
  public slabel = "";
  constructor(finger: number, id: string, label: string, width: string) {
    this.finger = finger;
    this.id = id;
    this.label = label;
    this.width = width;
  }
}

class WhitespaceKey {
  public id: string;
  public finger: number;
  public label: string;
  public width: string;
  public ch: string;
  public sch = "";
  public slabel = "";
  constructor(
    finger: number,
    id: string,
    label: string,
    ch: string,
    width: string
  ) {
    this.finger = finger;
    this.id = id;
    this.label = label;
    this.ch = ch;
    this.width = width;
  }
}

class BlankKey {
  public id = "";
  public finger: number;
  public label = "";
  public ch = "";
  public sch = "";
  public width = "";
  public slabel = "";
  constructor(finger: number) {
    this.finger = finger;
  }
}

const rowsSet = {
  en: [
    [
      new SymbolKey(0, "`", "~"),
      new SymbolKey(0, "1", "!"),
      new SymbolKey(1, "2", "@"),
      new SymbolKey(2, "3", "#"),
      new SymbolKey(3, "4", "$"),
      new SymbolKey(3, "5", "%"),
      new SymbolKey(6, "6", "^"),
      new SymbolKey(6, "7", "&"),
      new SymbolKey(7, "8", "*"),
      new SymbolKey(8, "9", "("),
      new SymbolKey(9, "0", ")"),
      new SymbolKey(9, "-", "_"),
      new SymbolKey(9, "=", "+"),
      new SpecialKey(9, "delete", "delete", "3rem"),
    ],
    [
      new SpecialKey(0, "tab", "tab", "3rem"),
      new AlphabetKey(0, "q"),
      new AlphabetKey(1, "w"),
      new AlphabetKey(2, "e"),
      new AlphabetKey(3, "r"),
      new AlphabetKey(3, "t"),
      new AlphabetKey(6, "y"),
      new AlphabetKey(6, "u"),
      new AlphabetKey(7, "i"),
      new AlphabetKey(8, "o"),
      new AlphabetKey(9, "p"),
      new SymbolKey(9, "[", "{"),
      new SymbolKey(9, "]", "}"),
      new SymbolKey(9, "|", "\\"),
    ],
    [
      new SpecialKey(0, "caps", "caps", "3.9rem"),
      new AlphabetKey(0, "a"),
      new AlphabetKey(1, "s"),
      new AlphabetKey(2, "d"),
      new AlphabetKey(3, "f"),
      new AlphabetKey(3, "g"),
      new AlphabetKey(6, "h"),
      new AlphabetKey(6, "j"),
      new AlphabetKey(7, "k"),
      new AlphabetKey(8, "l"),
      new SymbolKey(9, ";", ":"),
      new SymbolKey(9, "'", '"'),
      new WhitespaceKey(9, "enter", "enter", "\n", "3.9rem"),
    ],
    [
      new SpecialKey(0, "l-shift", "shift", "5.2rem"),
      new AlphabetKey(0, "z"),
      new AlphabetKey(1, "x"),
      new AlphabetKey(2, "c"),
      new AlphabetKey(3, "v"),
      new AlphabetKey(3, "b"),
      new AlphabetKey(6, "n"),
      new AlphabetKey(6, "m"),
      new SymbolKey(7, ",", "<"),
      new SymbolKey(8, ".", ">"),
      new SymbolKey(9, "/", "?"),
      new SpecialKey(9, "r-shift", "shift", "5.2rem"),
    ],
    [
      new SpecialKey(0, "ctrl", "ctrl", "3rem"),
      new SpecialKey(0, "alt", "alt", "3rem"),
      new SpecialKey(4, "cmd", "cmd", "3rem"),
      new WhitespaceKey(5, "space", "space", " ", "16.4rem"),
      new SpecialKey(5, "cmd", "cmd", "3rem"),
      new SpecialKey(9, "alt", "alt", "3rem"),
      new SpecialKey(9, "ctrl", "ctrl", "3rem"),
    ],
  ],
  ja: [
    [
      new BlankKey(0),
      new SymbolKey(0, "1", "!"),
      new SymbolKey(1, "2", '"'),
      new SymbolKey(2, "3", "#"),
      new SymbolKey(3, "4", "$"),
      new SymbolKey(3, "5", "%"),
      new SymbolKey(6, "6", "&"),
      new SymbolKey(6, "7", "'"),
      new SymbolKey(7, "8", "("),
      new SymbolKey(8, "9", ")"),
      new SymbolKey(9, "0", ""),
      new SymbolKey(9, "-", "="),
      new SymbolKey(9, "~", "^"),
      new SymbolKey(9, "￥", "|"),
      new SpecialKey(9, "delete", "delete", "3rem"),
    ],
    [
      new SpecialKey(0, "tab", "tab", "3rem"),
      new AlphabetKey(0, "q"),
      new AlphabetKey(1, "w"),
      new AlphabetKey(2, "e"),
      new AlphabetKey(3, "r"),
      new AlphabetKey(3, "t"),
      new AlphabetKey(6, "y"),
      new AlphabetKey(6, "u"),
      new AlphabetKey(7, "i"),
      new AlphabetKey(8, "o"),
      new AlphabetKey(9, "p"),
      new SymbolKey(9, "@", "`"),
      new SymbolKey(9, "[", "{"),
    ],
    [
      new SpecialKey(0, "caps", "caps", "3.9rem"),
      new AlphabetKey(0, "a"),
      new AlphabetKey(1, "s"),
      new AlphabetKey(2, "d"),
      new AlphabetKey(3, "f"),
      new AlphabetKey(3, "g"),
      new AlphabetKey(6, "h"),
      new AlphabetKey(6, "j"),
      new AlphabetKey(7, "k"),
      new AlphabetKey(8, "l"),
      new SymbolKey(9, ";", "+"),
      new SymbolKey(9, ":", "*"),
      new SymbolKey(9, "]", "}"),
      new WhitespaceKey(9, "enter", "enter", "\n", "3.9rem"),
    ],
    [
      new SpecialKey(0, "l-shift", "shift", "5.3rem"),
      new AlphabetKey(0, "z"),
      new AlphabetKey(1, "x"),
      new AlphabetKey(2, "c"),
      new AlphabetKey(3, "v"),
      new AlphabetKey(3, "b"),
      new AlphabetKey(6, "n"),
      new AlphabetKey(6, "m"),
      new SymbolKey(7, ",", "<"),
      new SymbolKey(8, ".", ">"),
      new SymbolKey(9, "/", "?"),
      new SymbolKey(9, "\\", "_"),
      new SpecialKey(9, "r-shift", "shift", "5rem"),
    ],
    [
      new SpecialKey(0, "ctrl", "ctrl", "3rem"),
      new SpecialKey(0, "alt", "alt", "3rem"),
      new SpecialKey(4, "cmd", "cmd", "3rem"),
      new BlankKey(4),
      new WhitespaceKey(5, "space", "space", " ", "13.7rem"),
      new BlankKey(5),
      new SpecialKey(5, "cmd", "cmd", "3rem"),
      new SpecialKey(9, "alt", "alt", "3rem"),
      new SpecialKey(9, "ctrl", "ctrl", "3rem"),
    ],
  ],
};

export interface Key {
  ch: string;
  id: string;
  sch: string;
  finger: number;
  width: string;
  label: string;
  slabel: string;
}

export default class Keyboard {
  public static keyboards: { [key: string]: Keyboard } = {
    en: new Keyboard(rowsSet.en),
    ja: new Keyboard(rowsSet.ja),
  };

  public static get(lang: string): Keyboard {
    return Keyboard.keyboards[lang];
  }

  public rows: Key[][];

  constructor(rows: Key[][]) {
    this.rows = rows;
  }

  public findByChar(ch: string): Key | undefined {
    for (const row of this.rows) {
      for (const key of row) {
        if (key.ch === ch || key.sch === ch) {
          return key;
        }
      }
    }
    return undefined;
  }

  public findByID(id: string): Key | undefined {
    for (const row of this.rows) {
      for (const key of row) {
        if (key.id === id || key.ch === id) {
          // XXX use ch as an ID
          return key;
        }
      }
    }
    return undefined;
  }
}
