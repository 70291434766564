import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Exercise from "./views/Exercise.vue";
import SignIn from "./views/SignIn.vue";
import Scores from "./views/Scores.vue";
import Auth from "./views/Auth.vue";
import UnsupportedBrowser from "./views/UnsupportedBrowser.vue";
import Admin from "./views/admin/Index.vue";
import AdminScores from "./views/admin/AdminScores.vue";

import firebase from "./firebase";
import platform from "platform";

import store from "./store";
import { User } from "./types";
import Session from "./store/session";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  linkExactActiveClass: "active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },

  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: { requiresAuth: true },
    },
    {
      path: "/exercises/:id",
      name: "exercise",
      component: Exercise,
      meta: { requiresAuth: true },
    },
    {
      path: "/sign-in",
      name: "sign-in",
      component: SignIn,
    },
    {
      path: "/scores",
      name: "scores",
      component: Scores,
      meta: { requiresAuth: true },
    },
    {
      path: "/admin",
      name: "admin",
      component: Admin,
      meta: { requiresAuth: true },
    },
    {
      path: "/admin/scores/:uid",
      name: "admin-scores",
      component: AdminScores,
      meta: { requiresAuth: true },
    },
    {
      path: "/auth",
      name: "auth",
      component: Auth,
    },
    {
      path: "/unsupported-browser",
      name: "unsupported-browser",
      component: UnsupportedBrowser,
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.name === "unsupported-browser") {
    next();
    return;
  }
  const unsupportedBrowsers = ["IE"];
  if (unsupportedBrowsers.includes(platform.name || "")) {
    next({
      path: "/unsupported-browser",
    });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (!requiresAuth) {
    next();
    return;
  }
  firebase.auth().onAuthStateChanged((user) => {
    store.commit("session/CURRENT_USER", { user });
    if (user) {
      if (user.email) {
        store.commit("session/CURRENT_USER_NAME", user.email);
      }
      if (!(store.state as { session: Session }).session.currentUserName) {
        firebase
          .firestore()
          .collection("users")
          .doc(user.uid)
          .get()
          .then((u) => {
            if (u.exists) {
              const data = u.data() as User;
              const name = data.name || data.fullname;
              store.commit("session/CURRENT_USER_NAME", name);
            }
          });
      }
      next();
    } else {
      const discordURI = process.env.VUE_APP_DISCORD_AUTHORIZE_URI;
      if (discordURI) {
        location.assign(discordURI);
      } else {
        next({
          path: "/sign-in",
        });
      }
    }
  });
});

export default router;
