









import { defineComponent, onMounted, ref } from "@vue/composition-api";

import AdminNav from "@/components/admin/AdminNav.vue";
import UserScores from "@/components/UserScores.vue";

import firebase from "@/firebase";

import { userName } from "@/util";
import { User } from "@/types";

export default defineComponent({
  components: {
    AdminNav,
    UserScores,
  },

  metaInfo: {
    title: "⚙ user summary",
  },

  setup(props, { root }) {
    const uid = root.$route.params.uid as string;
    const user = ref<User | null>(null);

    onMounted(() => {
      firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .get()
        .then((docSnapshot) => (user.value = docSnapshot.data() as User));
    });

    return {
      uid,
      user,
      userName,
    };
  },
});
