import data from "@/textbooks";

export interface Exercise {
  id: string;
  title: string;
  texts: string[];
  chapter_number: number;
  section_number: number;
  chapter: string;
}

export function findExerciseById(id: string): Exercise | undefined {
  return data.exercises.find((exercise) => exercise.id === id);
}

export function findNextExerciseById(id: string): Exercise | undefined {
  let thisFound = false;
  const next = data.exercises.find((e) => {
    if (e.id === id) {
      thisFound = true;
      return false;
    }
    return thisFound;
  });
  return next;
}

export function getExercises(): Exercise[] {
  return data.exercises;
}

export function exerciseExists(exid: string): boolean {
  return Boolean(findExerciseById(exid));
}

export function exerciseTitle(exid: string): string {
  return findExerciseById(exid)?.title || exid;
}
