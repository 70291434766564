import { Module, VuexModule, Mutation } from "vuex-module-decorators";

@Module({
  name: "session",
  namespaced: true,
})
export default class Session extends VuexModule {
  public currentUid = "";
  public currentUserName = "";
  public signedIn = false;

  @Mutation
  public CURRENT_USER(data: { user: firebase.default.User | null }): void {
    if (data.user) {
      this.currentUid = data.user.uid;
      this.signedIn = true;
    } else {
      this.currentUid = "";
      this.currentUserName = "";
      this.signedIn = false;
    }
  }

  @Mutation
  public CURRENT_USER_NAME(name: string): void {
    this.currentUserName = name;
  }
}
