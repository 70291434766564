







import { defineComponent } from "@vue/composition-api";

import { getModule } from "vuex-module-decorators";
import SessionStore from "@/store/session";

import UserScores from "@/components/UserScores.vue";

export default defineComponent({
  metaInfo: {
    title: "My scores",
  },

  components: {
    UserScores,
  },

  setup(props, { root }) {
    const sessionModule = getModule(SessionStore, root.$store);
    return {
      uid: sessionModule.currentUid,
    };
  },
});
