import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./firebase";

Vue.config.productionTip = false;

// Composition API
import VueCompositionApi from "@vue/composition-api";
Vue.use(VueCompositionApi);

// Bootstrap
import BootstrapVue from "bootstrap-vue";
Vue.use(BootstrapVue);

// FontAwesome
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBook,
  faKeyboard,
  faSpinner,
  faSignOutAlt,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faBook, faKeyboard, faSpinner, faSignOutAlt, faExclamationTriangle);
Vue.component("font-awesome-icon", FontAwesomeIcon);

// vue-meta
import VueMeta from "vue-meta";
Vue.use(VueMeta);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
