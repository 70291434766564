



































import { defineComponent, onMounted, reactive } from "@vue/composition-api";

import AdminNav from "@/components/admin/AdminNav.vue";

import firebase from "@/firebase";

import { exerciseTitle } from "@/store/exercise";
import { formatTime, calcScore, userName } from "@/util";
import { Score, User } from "@/types";

interface State {
  scores: Score[];
  users: Record<string, User>;
}

export default defineComponent({
  components: {
    AdminNav,
  },

  metaInfo: {
    title: "⚙ events",
  },

  setup() {
    const state: State = reactive({
      scores: [],
      users: {},
    });

    let usersBound = false;
    onMounted(() => {
      if (usersBound) {
        return;
      }

      firebase
        .firestore()
        .collection("users")
        .onSnapshot((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            state.users[doc.id] = doc.data() as User;
          });
        });
      usersBound = true;
    });

    let scoresBound = false;
    onMounted(() => {
      if (scoresBound) {
        return;
      }

      firebase
        .firestore()
        .collectionGroup("scores")
        .orderBy("time", "desc")
        .limit(500)
        .onSnapshot((querySnapshot) => {
          state.scores = querySnapshot.docs.map((doc) => doc.data() as Score);
        });
      usersBound = true;
    });

    function userNameOrUid(uid: string) {
      const user = state.users[uid];
      return user ? userName(user) : uid;
    }

    return {
      state,

      userNameOrUid,
      formatTime,
      exerciseTitle,
      calcScore,
    };
  },
});
