import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyC5K-NeLTbxRtwZydBF-9XcDyi1KT2Fmkw",
  databaseURL: "https://violet-bd3cd.firebaseio.com",
  storageBucket: "violet-bd3cd.appspot.com",
  authDomain: "violet-bd3cd.firebaseapp.com",
  messagingSenderId: "18901371375",
  projectId: "violet-bd3cd",
};

firebase.initializeApp(config);
export default firebase;
