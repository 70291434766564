













import { defineComponent } from "@vue/composition-api";

import NavBar from "@/components/NavBar.vue";

export default defineComponent({
  components: {
    NavBar,
  },

  metaInfo: {
    titleTemplate(titleChunk: string): string {
      return titleChunk ? `${titleChunk} | violet` : "violet";
    },
  },
});
