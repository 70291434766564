import { Module, VuexModule, Mutation } from "vuex-module-decorators";

@Module({
  name: "keyboard",
  namespaced: true,
})
export default class KeyboardConfiguration extends VuexModule {
  public keyboardType = "en";

  @Mutation
  public toggleKeyboardType(): void {
    if (this.keyboardType === "en") {
      this.keyboardType = "ja";
    } else {
      this.keyboardType = "en";
    }
  }

  @Mutation
  public setKeyboardType(keyboardType: string): void {
    this.keyboardType = keyboardType;
  }
}
