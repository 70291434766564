import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";

import keyboard from "@/store/keyboard";
import session from "@/store/session";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  modules: {
    keyboard,
    session,
  },
  plugins: [createPersistedState({ paths: ["keyboard"] })],
});
