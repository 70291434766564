









































import { getModule } from "vuex-module-decorators";
import KeyboardStore from "@/store/keyboard";
import SessionStore from "@/store/session";
import firebase from "@/firebase";
import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  setup(props, { root }) {
    const keyboardModule = getModule(KeyboardStore, root.$store);
    const sessionModule = getModule(SessionStore, root.$store);

    const currentUserName = computed(() => sessionModule.currentUserName);
    const signedIn = computed(() => sessionModule.signedIn);
    const keyboardType = computed(() => keyboardModule.keyboardType);

    const signOut = () => firebase.auth().signOut();
    function handleClickOnKeyboardTypeButton(e: MouseEvent): void {
      keyboardModule.toggleKeyboardType();

      // blur in order to remove forcus from the button,
      // without this, further space key hits may cause keyboard type toggles.
      if (e.target) {
        (e.target as HTMLButtonElement).blur();
      }
    }

    return {
      keyboardType,
      currentUserName,
      signedIn,

      signOut,
      handleClickOnKeyboardTypeButton,
    };
  },
});
