/* eslint-disable */
export default {
  "exercises": [
    {
      "texts": [
        "fat joy fat joy fat joy fat joy",
        "fit jam fit jam fit jam fit jam",
        "fun job fun job fun job fun job",
        "frog jump frog jump frog jump frog jump",
        "fate jojo fate jojo fate jojo fate jojo"
      ],
      "id": "index-finger-practice-1",
      "title": "Index Finger Practice (part 1)",
      "section_number": 1,
      "chapter": "Basic",
      "chapter_number": 1
    },
    {
      "texts": [
        "hunt retry hunt retry hunt retry hunt retry",
        "human egg human egg human egg human egg",
        "vigor bunny vigor bunny vigor bunny vigor bunny",
        "jumbo thumb jumbo thumb jumbo thumb jumbo thumb",
        "five hub five hub five hub five hub",
        "very hungry very hungry very hungry very hungry"
      ],
      "id": "index-finger-practice-2",
      "title": "Index Finger Practice (part 2)",
      "section_number": 2,
      "chapter": "Basic",
      "chapter_number": 1
    },
    {
      "texts": [
        "extended waikiki extended waikiki extended waikiki extended waikiki",
        "deep kick deep kick deep kick deep kick",
        "geek cook geek cook geek cook geek cook",
        "like coke, like coke, like coke, like coke,",
        "kind dad, kind dad, kind dad, kind dad,"
      ],
      "id": "middle-finger-practice",
      "title": "Middle Finger Practice",
      "section_number": 3,
      "chapter": "Basic",
      "chapter_number": 1
    },
    {
      "texts": [
        "local systems local systems local systems local systems",
        "fullcolor seesaw fullcolor seesaw fullcolor seesaw fullcolor seesaw",
        "posix toolbox posix toolbox posix toolbox posix toolbox",
        "wax swallows. wax swallows. wax swallows. wax swallows.",
        "slowdown xmas. slowdown xmas. slowdown xmas. slowdown xmas."
      ],
      "id": "third-finger-practice",
      "title": "Third Finger Practice",
      "section_number": 4,
      "chapter": "Basic",
      "chapter_number": 1
    },
    {
      "texts": [
        "aqua pajama aqua pajama aqua pajama aqua pajama",
        "alpaca japan alpaca japan alpaca japan alpaca japan",
        "pizzala q&a pizzala q&a pizzala q&a pizzala q&a",
        "fuzzy loop; fuzzy loop; fuzzy loop; fuzzy loop;"
      ],
      "id": "little-finger-practice",
      "title": "Little Finger Practice",
      "section_number": 5,
      "chapter": "Basic",
      "chapter_number": 1
    },
    {
      "texts": [
        "The quick brown fox jumps over the lazy dog",
        "The quick brown fox jumps over the lazy dog",
        "The quick brown fox jumps over the lazy dog",
        "The quick brown fox jumps over the lazy dog",
        "The quick brown fox jumps over the lazy dog"
      ],
      "id": "the-quick-brown-fox-jumps-over-the-lazy-dog",
      "title": "The quick brown fox jumps over the lazy dog",
      "section_number": 1,
      "chapter": "English sentences",
      "chapter_number": 2
    },
    {
      "texts": [
        "I am honored to be with you today at your commencement from one of the finest universities in the world.",
        "I never graduated from college.",
        "Truth be told, this is the closest I've ever gotten to a college graduation.",
        "Today I want to tell you three stories from my life.",
        "That's it. No big deal. Just three stories."
      ],
      "id": "steve-jobs",
      "title": "Steve Jobs",
      "section_number": 2,
      "chapter": "English sentences",
      "chapter_number": 2
    },
    {
      "texts": [
        "Dear friends, on 9 October 2012, the Taliban shot me on the left side of my forehead.",
        "They shot my friends, too. They thought that the bullets would silence us, but they failed.",
        "And out of that silence came thousands of voices.",
        "The terrorists thought they would change my aims and stop my ambitions.",
        "But nothing changed in my life except this: weakness, fear and hopelessness died.",
        "Strength, power and courage was born."
      ],
      "id": "malala-yousafzai",
      "title": "Malala Yousafzai",
      "section_number": 3,
      "chapter": "English sentences",
      "chapter_number": 2
    },
    {
      "texts": [
        "We choose to go to the moon.",
        "We choose to go to the moon in this decade and do the other things,",
        "not because they are easy, but because they are hard,",
        "because that goal will serve to organize and measure the best of our energies and skills,",
        "because that challenge is one that we are willing to accept,",
        "one we are unwilling to postpone, and one which we intend to win, and the others, too."
      ],
      "id": "we-choose-to-go-to-the-moon",
      "title": "We choose to go to the moon (from \"John F. Kennedy Moon Speech\")",
      "section_number": 4,
      "chapter": "English sentences",
      "chapter_number": 2
    },
    {
      "texts": [
        "Alice was beginning to get very tired of sitting by her sister on the bank,",
        "and of having nothing to do:",
        "once or twice she had peeped into the book her sister was reading,",
        "but it had no pictures or conversations in it,",
        "\"and what is the use of a book,\"",
        "thought Alice \"without pictures or conversation?\""
      ],
      "id": "alice-in-wonderland",
      "title": "Alice in Wonderland",
      "section_number": 5,
      "chapter": "English sentences",
      "chapter_number": 2
    },
    {
      "texts": [
        "A programming language is a formal language,",
        "which comprises a set of instructions used to produce various kinds of output.",
        "Programming languages are used in computer programming",
        "to create programs that implement specific algorithms.",
        "Thousands of different programming languages have been created,",
        "mainly in the computer field,",
        "although there are programmable machines that use a limited set of specific instructions,",
        "and many more still are being created every year."
      ],
      "id": "programming-language",
      "title": "Programming language (from \"Wikipedia\")",
      "section_number": 6,
      "chapter": "English sentences",
      "chapter_number": 2
    },
    {
      "texts": [
        "public class Main {",
        "public static void main(String[] args) {",
        "System.out.println(\"Hello World\");",
        "public class Main {",
        "public static void main(String[] args) {",
        "System.out.println(\"Hello World\");"
      ],
      "id": "hello-world",
      "title": "Hello World",
      "section_number": 1,
      "chapter": "Java",
      "chapter_number": 3
    },
    {
      "texts": [
        "public class Main {",
        "}",
        "public static void main(String[] args) {",
        "}",
        "System.out.println(1 * 60 * 60);",
        "System.out.println(\"hi\");",
        "public class Main {",
        "}",
        "public static void main(String[] args) {",
        "}",
        "System.out.println((1 + 2) * 3);",
        "System.out.println(\"hello\" + \"world\");"
      ],
      "id": "practice-java-programming",
      "title": "Practice Java Programming",
      "section_number": 2,
      "chapter": "Java",
      "chapter_number": 3
    },
    {
      "texts": [
        "import java.util.Scanner;",
        "Scanner scan = new Scanner(System.in);",
        "int a = scan.nextInt();",
        "double b = scan.nextDouble();",
        "String c = scan.nextLine();",
        "import java.util.Random;",
        "Random random = new Random();",
        "int d = random.nextInt();"
      ],
      "id": "java-api-practice",
      "title": "Java API Practice",
      "section_number": 3,
      "chapter": "Java",
      "chapter_number": 3
    },
    {
      "texts": [
        "int age;",
        "age = 20;",
        "double pi = 3.14;",
        "String message = \"hello\";",
        "int[] scores = new int[3];",
        "scores[0] = 30;",
        "scores[1] = 60;",
        "scores[2] = 90;",
        "double[] points = new double[5];",
        "points[0] = 1.2;",
        "points[1] = 2.3;",
        "String[] names = new String[3];",
        "names[0] = \"Alice\";",
        "names[1] = \"Bob\";",
        "names[2] = \"Charlie\";"
      ],
      "id": "java-data-type-practice",
      "title": "Java Data Type Practice",
      "section_number": 4,
      "chapter": "Java",
      "chapter_number": 3
    },
    {
      "texts": [
        "class Product {",
        "}",
        "String name;",
        "int price;",
        "Product() {",
        "}",
        "this.name = \"No Name\";",
        "this.price = 0;",
        "Product(String n, int p) {",
        "}",
        "this.name = n;",
        "this.price = p;",
        "void setName(String n) {",
        "}",
        "this.name = n;",
        "String getName() {",
        "}",
        "return this.name;",
        "void setPrice(int p) {",
        "}",
        "this.price = p;",
        "int getPrice() {",
        "}",
        "return this.price;",
        "int totalPrice(int unit) {",
        "}",
        "return price * unit;"
      ],
      "id": "java-class-definition-practice",
      "title": "Java Class Definition Practice",
      "section_number": 5,
      "chapter": "Java",
      "chapter_number": 3
    }
  ]
};
