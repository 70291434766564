
























import { computed, defineComponent } from "@vue/composition-api";

import Keyboard from "@/keyboard";
import KeyboardChart from "@/components/guide/KeyboardChart.vue";
import HandChart from "@/components/guide/HandChart.vue";

interface Props {
  ch: string;
  id: string;
  keyboardType: string;
}

export default defineComponent({
  props: ["ch", "id", "keyboardType"],

  components: {
    KeyboardChart,
    HandChart,
  },

  setup(props: Props) {
    const keyboard = computed(() => Keyboard.get(props.keyboardType));

    const keysToType = computed(() => {
      const keys: { [k: string]: boolean } = {};
      if (props.ch) {
        const k = keyboard.value.findByChar(props.ch);
        if (!k) {
          return keys;
        }
        keys[k.ch] = true;
        if (k.sch === props.ch) {
          // shift required
          if (k.finger < 5) {
            keys["r-shift"] = true;
          } else {
            keys["l-shift"] = true;
          }
        }
      } else {
        const k = keyboard.value.findByID(props.id);
        if (!k) {
          return keys;
        }
        keys[k.ch] = true;
      }

      return keys;
    });

    const fingers = computed(() => {
      const fingers: boolean[] = new Array(10).fill(false);
      const keys = keysToType.value;
      Object.keys(keys).forEach((ch) => {
        const k = keyboard.value.findByID(ch);
        if (k) {
          fingers[k.finger] = true;
        }
      });
      return fingers;
    });

    return {
      keyboard,
      keysToType,
      fingers,
    };
  },
});
