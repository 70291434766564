











import { computed, defineComponent } from "@vue/composition-api";

interface Props {
  pos: number;
  errors: Map<number, number>;
  text: string;
}

export default defineComponent({
  props: {
    pos: Number,
    errors: Map,
    text: String,
  },

  setup(props: Props) {
    const classFor = (i: number) => {
      return {
        typed: i < props.pos,
        typing: i === props.pos,
        typo: props.errors?.has(i),
      };
    };

    const chars = computed(() => Array.from(props.text));

    return {
      classFor,
      chars,
    };
  },
});
